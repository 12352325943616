import { Alert, Backdrop, Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Container, Divider, FormControl, Grid, InputLabel, Link, List, ListItem, MenuItem, Paper, Select, Snackbar, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esES from 'date-fns/locale/es';
import add from 'date-fns/add';
import { format } from 'date-fns';
import { Capitcha } from 'capitcha-npm';

import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Swal from 'sweetalert2'
import { height } from '@mui/system';

const Home = () => {

	const [especialidades, setEspecialidades] = useState([]);
	const [tipoDocumentos, setTipoDocumentos] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [catptchaValue, setCatptchaValue] = useState('');
	const [catptcha, setCatptcha] = useState('');
	const [msgAlert, setMsgAlert] = useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [especialidad, setEspecialidad] = useState('');

	const [programaciones, setProgramaciones] = useState([]);

	const [activeStep, setActiveStep] = useState(0);
	const [fecha, setFecha] = useState(new Date());
	const [programacion, setProgramacion] = useState({});
	const [importe, setImporte] = useState(0);
	const [token, setToken] = useState('');

	const [tipoDocumentoId, setTipoDocumentoId] = useState('');
	const [numDocumento, setNumDocumento] = useState('');
	const [nombres, setNombres] = useState('');
	const [apellidoPaterno, setApellidoPaterno] = useState('');
	const [apellidoMaterno, setApellidoMaterno] = useState('');
	const [correo, setCorreo] = useState('');
	const [telefono, setTelefono] = useState('');
	
	
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpenSnackbar(false);
	};

	useEffect(() => {
		fetchData();
	}, [])

	const fetchData = async () => {
		let resp = '';
		try {
			resp = await axios.get('especialidads');
			setEspecialidades(resp.data);

			resp = await axios.get('tipoDocumentos/1');
			setTipoDocumentos(resp.data);
		} catch (error) {

		}
	}

	const onChangeEspecialidad = async (valor) => {
		await setEspecialidad(valor);
		//obtener el precio
		let selEspecialidad = especialidades.find(item => item.id === valor);
		setImporte(selEspecialidad.importeOnline * 1);

		if (fecha && valor) {
			setLoading(true);
			try {
				let selFecha = format(fecha, 'Y-MM-dd');
				let { data } = await axios.get(`horarios/1/${selFecha}/${valor}`);
				setProgramaciones(data);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
	}

	const onChangeFecha = async (valor) => {
		await setFecha(valor);
		if (especialidad && valor) {
			setLoading(true);
			try {
				let selFecha = format(valor, 'Y-MM-dd');
				let { data } = await axios.get(`horarios/1/${selFecha}/${especialidad}`);
				setProgramaciones(data);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
	}

	
	const selProgramacion = async(item) => {
		await setProgramacion(item);
		document.getElementById('btnContinuar').scrollIntoView();
	}

	const pasoOne = async () => {
		if (programacion.id > 0) {
			await setActiveStep(1);
			
			setTimeout(() => {
				document.querySelector(".captcha_reload span").click();
			}, 1000);
			
			document.getElementById('pasoDo').scrollIntoView();			
		} else {
			setMsgAlert('Debe selecionar un turno valido!');
			setOpenSnackbar(true);
		}
	}

	window.culqi = async (e) => {
		// eslint-disable-next-line no-undef
		let cq = Culqi;
		try{
			if (cq.token) {  // ¡Objeto Token creado exitosamente!				
				cq.close();
				setOpen(true);
				const token = cq.token;
				//En esta linea de codigo debemos enviar el "Culqi.token.id"
				//hacia tu servidor con Ajax
				let form = {
					culqi: token,
					token: token.id,
					numDocumento: numDocumento,
					tipoDocumentoId: tipoDocumentoId,
					nombres: nombres,
					apellidoPaterno: apellidoPaterno,
					apellidoMaterno: apellidoMaterno,
					correo: correo,
					telefono: telefono,
					programacion: programacion,
					importe: importe
				}
				let {data} = await axios.post('/reservar', form);
				setToken(data.token);
	
			} else if (cq.order) {  // ¡Objeto Order creado exitosamente!
				const order = cq.order;
				console.log(`Se ha creado el objeto Order: ${order}.`);
	
			} else {
				// Mostramos JSON de objeto error en consola
				console.log(`Error : ${cq.error.merchant_message}.`);
			}
			await setOpen(false);
			await setActiveStep(2);
			document.getElementById('pasoThree').scrollIntoView();
		}catch(error){
			console.log(error);
			setOpen(false);
			if(error.response){
				let resp = error.response;
				let data = resp.data;
				if(resp.status == 400){
					return Swal.fire({
						title: 'Error!',
						text: data.msg,
						icon: 'error',
						confirmButtonText: 'Cerrar'
					})
				}
			}
			return Swal.fire({
				title: 'Error!',
				text: 'Se presento un error en el pago, verifique que no tenga un recargo, en caso comuniquese con CDERMA.',
				icon: 'error',
				confirmButtonText: 'Cerrar'
			})
		}
	}

	const onChangeTipoDocumentoId = async (tipoDocumentoId) => {
		setTipoDocumentoId(tipoDocumentoId);
		if(numDocumento !=''){
			let {data} = await axios.get(`paciente/${tipoDocumentoId}/${numDocumento}`);
			if(data){
				setNombres(data.nombres);
				setApellidoPaterno(data.apellidoPaterno);
				setApellidoMaterno(data.apellidoMaterno);
				setCorreo(data.correo);
				setTelefono(data.telefono);
			}
		}
		
	}

	const onChangeNumDocumento = async (numDocumento) => {
		setNumDocumento(numDocumento);
		if(tipoDocumentoId > 0){
			let {data} = await axios.get(`paciente/${tipoDocumentoId}/${numDocumento}`);

			if(data){
				setNombres(data.nombres);
				setApellidoPaterno(data.apellidoPaterno);
				setApellidoMaterno(data.apellidoMaterno);
				setCorreo(data.correo);
				setTelefono(data.telefono);
			}
		}		
	}

	const submit = async (e) => {
		e.preventDefault()
		setMsgAlert('');
		if(catptchaValue != catptcha){			
			setMsgAlert('catptcha Incorecto!, puede cambiar el codigo del catch en caso que no lo llege a visualizar bien.');
			setOpenSnackbar(true);
			return false;
		}
		let logo = window.location.origin+'/favicon.png';
		// eslint-disable-next-line no-undef
		Culqi.options({
			lang: 'auto',
			paymentMethods: {
				tarjeta: true,
				bancaMovil: false,
				agente: false,
				billetera: false,
				cuotealo: false
			},
			style: {
				logo: logo,
			}
		});
		// eslint-disable-next-line no-undef
		Culqi.settings({
			title: 'Cderma Online Store',
			currency: 'PEN',
			amount: importe * 100
		});
		// eslint-disable-next-line no-undef
		Culqi.open();
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
			}}
		>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<div className="cabecera">
				<div className="text-center">
					<img src="./files/img/logo.png"></img>
				</div>
				<Container maxWidth="lg" className="mt-4">
					<Box sx={{ width: '100%' }}>
						<Stepper activeStep={activeStep} alternativeLabel>
							<Step key='0'>
								<StepLabel>Seleccionar Turno</StepLabel>
							</Step>
							<Step key='1'>
								<StepLabel>Ingresar Datos</StepLabel>
							</Step>
							<Step key='2'>
								<StepLabel>Registrar Cita</StepLabel>
							</Step>
						</Stepper>
					</Box>
				</Container>
			</div>
			{
				activeStep === 0 &&
				<React.Fragment>
					<Container maxWidth="lg" className="my-4">
						<Grid container spacing={4}>
							<Grid item xs={12} lg={4}>
								<h6 className="text-center font-weight-bold mb-3"><u> Selecciona el turno de tu preferencia</u></h6>	
								<div className="numTitle"><span>1</span> Seleccionar fecha para su cita</div>	
								<FormControl fullWidth className="mb-3">
									<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esES}>
										<StaticDatePicker
											label="Fecha decha"
											displayStaticWrapperAs="desktop"
											openTo="day"
											value={fecha}
											minDate={new Date()}
											maxDate={add(new Date(), { months: 2 })}
											onChange={(newValue) => { onChangeFecha(newValue); }}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</FormControl>
								<div className="numTitle"><span>2</span> Seleccionar Especialidad a Consultar</div>	
								<FormControl fullWidth className="mb-3 background-white">
									<InputLabel id="demo-simple-select-label">Especialidad</InputLabel>
									<Select
										value={especialidad}
										label="Especialidad"
										onChange={e => onChangeEspecialidad(e.target.value)}
									>
										{especialidades.map(item => {
											return (
												<MenuItem value={item.id}>{item.nombre}</MenuItem>
											)
										})

										}
									</Select>
								</FormControl>
								<div className="mb-3 text-center">
									{
										loading && <CircularProgress />
									}

								</div>
							</Grid>
							{
								programaciones.length > 0 ?
								<React.Fragment>
									<Grid item xs={12} lg={8}>
										<h6 className="text-center font-weight-bold">Seleccione el turno con el profesional de su preferencia</h6>
										<Divider className='mb-2'></Divider>
										<div className="horarios">
											{
												programaciones.map(item => {
													return (
														<Card className="mb-3">
															<CardContent className="p-2">
																<Grid container spacing={1}>
																	<Grid item xs={5} sm={3} lg={2}>
																		<img src={item.fotoDoctor} className="img-fluid" alt={item.doctor} />
																		<div className="d-block d-md-none text-small mt-2">
																			<b>{item.doctor}</b>
																			<Divider></Divider>
																			<b>{item.especialidad}</b>
																		</div>
																	</Grid>
																	<Grid item xs={7} sm={9} lg={10}>
																		<div className="d-none d-md-block">
																			<div className="d-flex justify-content-between text-color-2">
																				<b>{item.doctor}</b>
																				<b>{item.especialidad}</b>
																			</div>
																			<Divider></Divider>
																		</div>																	
																		{
																			item.horarios.map(item => {
																				return (
																					<Chip className={(programacion.id === item.id) ? 'chip-active mt-2 mx-1':'mt-2 mx-1'} label={item.hora} variant="outlined" clickable
																						onClick={() => selProgramacion(item)}
																						color='primary' />
																				)
																			})
																		}
																	</Grid>
																</Grid>
															</CardContent>
														</Card>
													)
												})
											}
										</div>
									</Grid>
									<Grid item xs={12} className="text-center" id="btnContinuar">
										<Button className="btn-radius" variant="contained" size='large' onClick={pasoOne}>Continuar</Button>
									</Grid>
								</React.Fragment>
								:
								<React.Fragment>
									<Grid item xs={12} lg={8}>
										<h5 className="text-center font-weight-bold text-color-1">Separa tu cita Online y Obtén hasta un descuento del 10%</h5>
										<img src="fondo2.jpg" className='img-fluid img-thumbnail mt-lg-2'></img>
										<div className="w-100 d-none justify-content-center">
										<h6 className="text-center font-weight-bold">Nuestro Servcios</h6>
											<TableContainer sx={{ maxWidth: 550 }} component={Paper}>
												<Table  aria-label="simple table customized">
													<TableHead>
														<TableRow>
															<TableCell>Especialidad</TableCell>
															<TableCell align="right">Precio</TableCell>
															<TableCell align="right">Precio Online</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{especialidades.map((row) => (
															<TableRow
																hover
																key={row.id}
																sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
															>
																<TableCell component="th" scope="row">
																	{row.nombre}
																</TableCell>
																<TableCell align="right">S/. {(row.importe*1).toFixed(2)}</TableCell>
																<TableCell align="right">S/. {(row.importeOnline*1).toFixed(2)}</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
										{
											(especialidad > 0 &&  !loading) &&
											<h6 className="mt-4 text-center font-weight-bold">Lo sentimos, no contamos con turnos disponibles para la fecha y especialidad seleccionada</h6>
										}
									</Grid>
								</React.Fragment>
							}
						</Grid>
					</Container>
				</React.Fragment>
			}
			{
				activeStep === 1 &&
				<React.Fragment>
					<Container maxWidth="md" className="my-5" id="pasoDo">
						<Grid container spacing={4}>
							<Grid item xs={12} md={6}>
								<Card sx={{ minWidth: 275 }} className="mb-4">
									<CardContent>
										<h5 className="text-center font-weight-bold pb-4">Turno seleccionado para cita</h5>
										<table style={{fontSize:"0.9rem"}}>
											<tr>
												<td style={{width:"100px"}}><b>Medico</b></td>
												<td>: {programacion.doctor}</td>
											</tr>
											<tr>
												<td><b>Especialidad</b></td>
												<td>: {programacion.especialidad}</td>
											</tr>
											<tr>
												<td><b>Fecha Hora</b></td>
												<td>: {programacion.fechaHora}</td>
											</tr>
											<tr>
												<td><b>Costo Cita</b></td>
												<td>: S/ {importe.toFixed(2)}</td>
											</tr>
											<tr>
												<td><b>Consultorio</b></td>
												<td>: {programacion.consultorio}</td>
											</tr>
											<tr>
												<td><b>Sede</b></td>
												<td>: {programacion.sede}</td>
											</tr>
										</table>
									</CardContent>									
								</Card>								
								<Button variant="outlined" size='large' onClick={() => setActiveStep(0)}>Volver</Button>
							</Grid>
							<Grid item xs={12} md={6}>
								<form onSubmit={submit} autoComplete="off">
									<div className="row">
										<div className="col-12 col-lg-6">
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Tipo Documento" variant="outlined" required select size="small"
													onChange={e => onChangeTipoDocumentoId(e.target.value)}>
													{tipoDocumentos.map((option) => (
														<MenuItem key={'TipoDocumento_'+option.id} value={option.id}>
															{option.nombre}
														</MenuItem>
													))}
												</TextField>
											</FormControl>
										</div>
										<div className="col-12 col-lg-6">
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Numero" variant="outlined" maxRows="15" required size="small"
													onBlur={e => onChangeNumDocumento(e.target.value)} />
											</FormControl>
										</div>
										<div className="col-12">
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Apellido Paterno" variant="outlined" maxRows="15" required size="small" value={apellidoPaterno}
													onChange={e => setApellidoPaterno(e.target.value)}
												/>
											</FormControl>
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Apellido Materno" variant="outlined" maxRows="15" required size="small" value={apellidoMaterno}
													onChange={e => setApellidoMaterno(e.target.value)} />
											</FormControl>
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Nombres" variant="outlined" maxRows="15" required size="small" value={nombres}
													onChange={e => setNombres(e.target.value)} />
											</FormControl>
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Correo" variant="outlined" maxRows="15" required size="small" type="email" value={correo}
													onChange={e => setCorreo(e.target.value)} />
											</FormControl>
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Telefono" variant="outlined" maxRows="15" size="small" value={telefono}
													onChange={e => setTelefono(e.target.value)} />
											</FormControl>
											<Capitcha setCaptchaText={setCatptchaValue} />
											<FormControl fullWidth>
												<TextField margin="normal" id="outlined-basic" label="Catptcha" variant="outlined"  size="small"
													onChange={e => setCatptcha(e.target.value)} />
											</FormControl>
										</div>
									</div>
									<div className='text-center mt-2 mb-3'>
										<Button type='submit' variant="contained" color="success" size='large'>Pagar S/ {importe.toFixed(2)}</Button>
									</div>
								</form>
							</Grid>
						</Grid>
					</Container>
				</React.Fragment>
			}
			{
				activeStep === 2 &&
				<React.Fragment>
					<Container maxWidth="md" className="my-5" id="pasoThree">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<h3 className="text-center mb-4">Cita Registra exitosamente</h3>
								<p className="text-muted font-weight-bold mb-0">Se ha registrado una cita médica con los siguientes datos:</p>
								<List>
									<ListItem>
										<span><b>Código Cita: </b> {programacion.id}</span>
									</ListItem>
									<ListItem>
										<span><b>Médico: </b> {programacion.doctor}</span>
									</ListItem>
									<ListItem>
										<span><b>Paciente: </b> {apellidoPaterno+' '+apellidoMaterno+' '+nombres}</span>
									</ListItem>
									<ListItem>
										<span><b>Especialidad: </b> {programacion.especialidad}</span>
									</ListItem>
									<ListItem>
										<span><b>Fecha - Hora: </b> {programacion.fechaHora}</span>
									</ListItem>
									<ListItem>
										<span><b>Código Pago: </b>{token}</span>
									</ListItem>
								</List>
								<Divider></Divider>
								<p className="text-muted">Nos pondremos en contacto con usted en las próximas horas</p>
								<p className="text-muted"><b>Recuerde:</b> El tiempo de anticipación debe ser presentarse, 30 minutos de anticipación.</p>
								<Divider></Divider>
								<div className="d-flex justify-content-between w-100 mt-3">
									<a className="btn btn-info" href="https://www.cderma.pe">llevar a pagina principal</a>
									<button type="button" className="btn btn-warning" onClick={ () => {window.location.reload()} }>Generar otra Cita</button>
								</div>
							</Grid>
						</Grid>
					</Container>
				</React.Fragment>
			}
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<img src="./files/img/pie-2.jpeg" class="img-fluid mb-3 w-75" style={{height:'70px'}} alt=""/>
						<img src="./files/img/pie-1.jpeg" class="img-fluid mb-3 w-100" style={{height:'150px'}} alt=""/>
					</div>
				</div>
			</div>

			<Box
				component="footer"
				sx={{
					py: 3,
					px: 2,
					mt: 'auto',
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? theme.palette.grey[200]
							: theme.palette.grey[800],
				}}
			>
				<Container maxWidth="lg">
					<Grid container spacing={2}>
						<Grid item xs={12} lg={4}>
							<Link className="d-block mb-1" color="inherit" underline="none" href="https://www.google.com/maps/place/Cl%C3%ADnica+Dermatol%C3%B3gica+Cderma/@-12.1283865,-76.9936519,17z/data=!3m1!4b1!4m5!3m4!1s0x9105c7f5f0dbba79:0xf979238dab8e7d28!8m2!3d-12.1283865!4d-76.9936519" target="_blank">
								<AddLocationAltOutlinedIcon />Av. Alfredo Benavides 4314 - Santiago de Surco
							</Link>
							<Link className="d-block mb-1" color="inherit" underline="none" href="mailto:contactenos@cderma.pe" target="_blank">
								<EmailIcon /> contactenos@cderma.pe
							</Link>
							<Link className="d-block mb-1" color="inherit" underline="none" href="tel:(01) 271 7302" target="_blank">
								<LocalPhoneIcon /> 271 7302
							</Link>

						</Grid>
						<Grid item xs={12} lg={4}>
							<Typography className='text-center text-small' color="text.secondary">
								{'Copyright © '}
								CDERMA
								{new Date().getFullYear()}
								{'.'}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Typography className='text-center' color="text.secondary">
								<div className="d-block mb-2">
									<Link href="https://www.facebook.com/Clinica-Dermatologica-Cderma-113317525395347/" target="_blank">
										<FacebookOutlinedIcon fontSize="large" />
									</Link>
									<Link href="https://www.youtube.com/channel/UCbGDWBI2uHazVJQz71fmY6g/videos" target="_blank">
										<YouTubeIcon fontSize="large" />
									</Link>
									<Link href="https://www.instagram.com/clinicacderma/" target="_blank">
										<InstagramIcon fontSize="large" />
									</Link>
									<Link href="https://api.whatsapp.com/send?phone=51924679088" target="_blank">
										<WhatsAppIcon fontSize="large" />
									</Link>
								</div>
								<Link className="d-block mb-1" color="inherit" href="https://www.cderma.pe" underline="none">
									Visite nuestra Web
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>				
				<Alert severity="warning">{msgAlert}</Alert>
			</Snackbar>
		</Box>
	);
};

export default Home;