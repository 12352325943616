import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

// Developer
var baseURL = 'http://127.0.0.1:8000/api/webcitas/';

// QA
if(window.location.origin == 'http://webclinic.projdevs.com' || window.location.origin == 'http://www.webclinic.projdevs.com'){
    baseURL = 'http://apiclinic.projdevs.com/api/webcitas/';
}

// Produccion
if(window.location.origin == 'https://cdermaonline.net.pe' || window.location.origin == 'https://www.cdermaonline.net.pe'){
    baseURL = 'https://api.cdermaonline.net.pe/api/webcitas/';
}

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
